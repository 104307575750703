import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography
} from '@material-ui/core';
import axios from 'axios';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { SocketContext } from 'src/context/SocketContext';

const New = ({ onRerender }) => {
  const { user } = useSelector(state => state.account);
  const { enqueueSnackbar } = useSnackbar();
  const [values, setValues] = useState({
    user_id: user.id,
    role_id: user?.roles?.[0].id,
    sent_to_role_id: 4,
    subject: '',
    remarks: '',
    message: '',
    datetime: moment(new Date()).format('YYYY-DD-MM HH:mm')
  });
  const { socket } = React.useContext(SocketContext);

  const [isProcess, setIsProcess] = useState(false);
  const roles = [
    { label: 'FIRE', value: 4 },
    { label: 'ASSESSOR', value: 5 },
    { label: 'ENGINEERING', value: 6 },
    { label: 'FIRE', value: 7 },
    { label: 'MPDO', value: 8 },
    { label: 'RHU', value: 9 },
    { label: 'MAO', value: 10 },
    { label: 'PESO', value: 11 },
    { label: 'BPLO', value: 12 }
  ];

  const onSent = () => {
    let error = [];

    if (values.subject?.trim() === '' || values.subject.length === 0) {
      error = 'subject-error';
      enqueueSnackbar('Subject is a required field.', {
        variant: 'error'
      });
    }

    if (values.remarks?.trim() === '' || values.remarks.length === 0) {
      error = 'remarks-error';
      enqueueSnackbar('Remarks is a required field.', {
        variant: 'error'
      });
    }

    if (values.message?.trim() === '' || values.message.length === 0) {
      error = 'message-error';
      enqueueSnackbar('Message is a required field.', {
        variant: 'error'
      });
    }

    if (error.length > 0) {
      console.log('Form has an error: ', error);
      return;
    }

    setIsProcess(true);
    socket.emit('sent-inquiry', values);
    setIsProcess(false);
    onRerender();
  };

  return (
    <Box>
      <Box>
        <Typography variant="h5"> New Inquiry</Typography>
      </Box>

      <Box my={3}>
        <TextField
          fullWidth
          value={values.sent_to_role_id}
          onChange={e =>
            setValues({
              ...values,
              sent_to_role_id: e.target.value
            })
          }
          variant="outlined"
          select
          InputLabelProps={{ shrink: true }}
          SelectProps={{ native: true }}
          label={'Office'}
        >
          {roles.map((data, key) => {
            return (
              <option key={key} value={data.value}>
                {' '}
                {data.label}{' '}
              </option>
            );
          })}
        </TextField>
      </Box>

      <Box my={3}>
        <TextField
          fullWidth
          value={values.subject}
          onChange={e =>
            setValues({
              ...values,
              subject: e.target.value
            })
          }
          variant="outlined"
          multiline
          minRows={2}
          label={'Subject'}
        />
      </Box>

      <Box my={3}>
        <TextField
          fullWidth
          value={values.remarks}
          onChange={e =>
            setValues({
              ...values,
              remarks: e.target.value
            })
          }
          variant="outlined"
          multiline
          minRows={2}
          label={'Remarks'}
        />
      </Box>

      <Box my={3}>
        <TextField
          fullWidth
          value={values.message}
          onChange={e =>
            setValues({
              ...values,
              message: e.target.value
            })
          }
          variant="outlined"
          multiline
          minRows={2}
          label={'Message'}
        />
      </Box>

      <Box>
        <Box>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => onSent()}
            disabled={isProcess}
            startIcon={
              isProcess && <CircularProgress size={16} color="inherit" />
            }
          >
            Sent Inquiry
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default New;
